import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { css, useTheme } from "@emotion/react"
import {
  colorPrimatives,
  fontFamilies,
  fontSizes,
  mediaQueries,
} from "../styles/theme"
import Text from "../styles/typography"
import { StaticImage } from "gatsby-plugin-image"
import moment from "moment"
import { Button } from "./Button"
import _ from "lodash"
import TagList from "./Taglist"
import config from "../../data/SiteConfig"

const SHOW_MORE = "SHOW_MORE"

function PostListing({ postList }) {
  const theme = useTheme()

  const [numDisplayedPosts, setNumDisplaedPosts] = useState(config.postsPerPage)

  const postListItems =
    numDisplayedPosts < postList.length
      ? [...postList.slice(0, numDisplayedPosts), SHOW_MORE]
      : postList

  // postList.shift()
  // postList.shift()
  // postList.shift()

  let nextStepJump = 3
  let nextStep = 1

  return (
    <ul
      css={css`
        ${mediaQueries.md} {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
        }

        list-style: none;
      `}
    >
      {
        /* Your post list here. */
        postListItems.map((post, i) => {
          const currentIndex = i + 1
          let isWide = false

          if (nextStep === currentIndex) {
            isWide = true
            const isOdd = currentIndex % 2 === 1
            nextStepJump = isOdd ? 3 : 1
            nextStep += nextStepJump
          }

          return (
            <li
              key={post === SHOW_MORE ? post : post.title}
              css={css`
                display: block;
                position: relative;
                padding: 32px;
                border-bottom: 1px solid ${colorPrimatives.tumbleweed};

                ${post === SHOW_MORE &&
                css`
                  &:last-child {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    ${postListItems.length % 2 === 1 &&
                    "grid-column-end: span 3;"}
                  }
                `}

                ${mediaQueries.md} {
                  border-bottom: 0.5px solid ${colorPrimatives.tumbleweed};
                  border-right: 0.5px solid ${colorPrimatives.tumbleweed};
                  border-top: none;
                  &:nth-child(1),
                  &:nth-child(2) {
                    border-top: none;
                  }
                  &:nth-child(even) {
                    border-right: none;
                  }
                  ${post === SHOW_MORE &&
                  css`
                    border-right: none;
                  `}
                  ${postList.length % 2 === 0 && "&:nth-last-child(2),"}
                  &:nth-last-child(1) {
                    border-bottom: none;
                  }
                }

                &:hover {
                  background-color: ${colorPrimatives.white};
                }
                ${post === SHOW_MORE &&
                css`
                  &:hover {
                    background-color: transparent;
                  }
                `}

                ${mediaQueries.md} {
                  ${isWide &&
                  css`
                    grid-column-end: span 2;
                  `}
                }
              `}
            >
              {post === SHOW_MORE ? (
                <Button
                  onClick={() => {
                    setNumDisplaedPosts(numDisplayedPosts + config.postsPerPage)
                  }}
                >
                  Show More
                </Button>
              ) : (
                <>
                  <Text
                    css={css`
                      font-size: ${fontSizes.xs};
                      font-family: ${fontFamilies.tajawal};
                      margin-bottom: 8px;
                      color: ${theme.color.quote};
                    `}
                  >
                    {moment(post.date).format("LL")}
                  </Text>
                  <div
                    css={css`
                      ${mediaQueries.md} {
                        height: 320px;

                        ${isWide &&
                        css`
                          height: initial;
                          display: flex;
                        `}
                      }
                    `}
                  >
                    <img
                      src={post.cover}
                      css={css`
                        width: 100%;
                        height: 240px;
                        object-fit: cover;
                        flex-shrink: 0;

                        ${mediaQueries.md} {
                          ${isWide
                            ? css`
                                width: 240px;
                                height: 320px;
                                margin-right: 16px;
                              `
                            : css`
                                width: 100%;
                                height: 120px;
                              `}
                        }
                      `}
                    />
                    <div
                      css={css`
                        width: 100%;
                        margin-top: 16px;
                        ${mediaQueries.md} {
                          ${isWide && "margin-top: 0"}
                        }
                      `}
                    >
                      <Link
                        to={post.path}
                        css={css`
                          &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                          }
                        `}
                      >
                        <Text
                          el="h2"
                          fontSize={fontSizes.md}
                          css={css`
                            font-size: ${fontSizes.md};
                            ${mediaQueries.md} {
                              font-size: ${fontSizes.md};
                            }
                          `}
                        >
                          {post.title}
                        </Text>
                      </Link>
                      <Text
                        css={css`
                          margin: 8px 0;

                          ${mediaQueries.md} {
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                          }
                        `}
                      >
                        {post.excerpt}
                      </Text>
                    </div>
                  </div>
                  {post.tags && <TagList tags={post.tags}></TagList>}
                </>
              )}
            </li>
          )
        })
      }
    </ul>
  )
}

export default PostListing
